import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import store from './redux/store';
import { Provider } from 'react-redux';
import './styles/calendar.scss';
import App from './app/App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import moment from 'moment-timezone';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

moment.tz.setDefault('UTC');

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
);

serviceWorkerRegistration.register();
reportWebVitals();
